<template>
  <b-container class="py-bw-5 text-left" id="privacyPolicy">
    <b-row>
      <b-col cols="12">
        <h2 class="mb-4 h2-title">
          Datenschutzerklärung
        </h2>
        <p class="p-bw">
          Barliner Workout, Inh. Denis Vitaliev, Heinrich-Heine-Str. 5, 10179 Berlin (nachfolgend „Barliner Workout oder
          „Wir“ genannt) ist Betreiber von der Internetseite www.barlinerworkout.com.
          <br><br>
          Wir nehmen den Schutz Deiner personenbezogenen Daten sehr ernst. Evope beachtet bei der Erhebung, Verarbeitung
          und Nutzung Deiner Daten die Vorgaben des der europäischen Datenschutz Grundverordnung (EU-DSGVO), dem
          Bundesdatenschutzgesetzes (BDSG) sowie des Telemediengesetzes (TMG). Die nachfolgende Erklärung gibt Dir einen
          Überblick darüber, wie Evope Datenschutz gewährleistet und welche Art von Daten zu welchem Zweck erhoben werden.
        </p>

        <h4 class="my-4 h4-bw">1. Zugriffsdaten und Hosting</h4>
        <p class="p-bw">
          Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite
          speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der
          angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden
          Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.
          <br><br>
          Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite
          sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der
          Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten
          Darstellung unseres Angebots. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs
          gelöscht.
        </p>

        <h5 class="my-4 h5-bw">
          Hostingdienstleistungen durch einen Drittanbieter
        </h5>
        <p class="p-bw">
          Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die Dienste zum Hosting und
          zur Darstellung der Webseite. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
          berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Daten, die im Rahmen der Nutzung
          dieser Webseite oder in dafür vorgesehenen Formularen im Onlineshop wie folgend beschrieben erhoben werden,
          werden auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in dem hier erläuterten
          Rahmen statt.
          <br><br>
          Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.
        </p>

        <h4 class="my-4 h4-bw">2. Datenerhebung und -verwendung zur Vertragsabwicklung und bei Eröffnung eines
          Kundenkontos</h4>
        <p class="p-bw">
          Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung, bei einer Kontaktaufnahme mit
          uns (z.B. per Kontaktformular oder E-Mail) oder bei Eröffnung eines Kundenkontos freiwillig mitteilen.
          Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur
          Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme oder Eröffnung des Kundenkontos benötigen und Sie
          ohne deren Angabe die Bestellung und/ oder die Kontoeröffnung nicht abschließen, bzw. die Kontaktaufnahme nicht
          versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir
          verwenden die von ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und
          Bearbeitung Ihrer Anfragen. Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden
          Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen
          Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt
          haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über
          die wir Sie in dieser Erklärung informieren. Die Löschung Ihres Kundenkontos ist jederzeit möglich und kann
          entweder durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder über eine dafür vorgesehene
          Funktion im Kundenkonto erfolgen.
        </p>

        <h4 class="my-4 h4-bw">3. Datenweitergabe</h4>
        <p class="p-bw">
          Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an das mit der Lieferung
          beauftragte Versandunternehmen weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist. Je nach dem,
          welchen Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir zur Abwicklung von Zahlungen die
          hierfür erhobenen Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut und ggf. von uns beauftragte
          Zahlungsdienstleister weiter bzw. an den ausgewählten Zahlungsdienst. Zum Teil erheben die ausgewählten
          Zahlungsdienstleister diese Daten auch selbst, soweit Sie dort ein Konto anlegen. In diesem Fall müssen Sie sich
          im Bestellprozess mit Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden. Es gilt insoweit die
          Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.
        </p>

        <h4 class="my-4 h4-bw">4. E-Mail-Newsletter und Postwerbung</h4>
        <h5 class="my-4 h5-bw">E-Mail-Werbung mit Anmeldung zum Newsletter</h5>
        <p class="p-bw">
          Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür erforderlichen oder gesondert von Ihnen
          mitgeteilten Daten, um Ihnen regelmäßig unseren E-Mail-Newsletter aufgrund Ihrer Einwilligung gemäß Art. 6 Abs.
          1 S. 1 lit. a DSGVO zuzusenden.
          <br><br>
          Die Abmeldung vom Newsletter ist jederzeit möglich und kann entweder durch eine Nachricht an die unten
          beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link im Newsletter erfolgen. Nach Abmeldung
          löschen wir Ihre E-Mail-Adresse, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt
          haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über
          die wir Sie in dieser Erklärung informieren.
        </p>

        <h5 class="my-4 h5-bw">E-Mail-Werbung ohne Anmeldung zum Newsletter und Ihr Widerspruchsrecht</h5>
        <p class="p-bw">
          Wenn wir Ihre E-Mail-Adresse im Zusammenhang mit dem Verkauf einer Ware oder Dienstleistung erhalten und Sie dem
          nicht widersprochen haben, behalten wir uns vor, Ihnen auf Grundlage von § 7 Abs. 3 UWG regelmäßig Angebote zu
          ähnlichen Produkten, wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden. Dies dient der
          Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer werblichen
          Ansprache unserer Kunden. Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch eine Nachricht an
          die unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link in der Werbemail
          widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.
          <br><br>
          Der Newsletter wird im Rahmen einer Verarbeitung in unserem Auftrag durch Mailchimp (www.klaviyo.com) versendet,
          an die wir Ihre E-Mail-Adresse hierzu weitergeben.
        </p>

        <h5 class="my-4 h5-bw">Postwerbung und Ihr Widerspruchsrecht</h5>
        <p class="p-bw">
          Darüber hinaus behalten wir uns vor, Ihren Vor- und Nachnamen sowie Ihre Postanschrift für eigene Werbezwecke zu
          nutzen, z.B. zur Zusendung von interessanten Angeboten und Informationen zu unseren Produkten per Briefpost.
          Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an
          einer werblichen Ansprache unserer Kunden gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
        </p>

        <h4 class="my-4 h4-bw">5. Cookies und Webanalyse</h4>
        <p class="p-bw">
          Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, um
          passende Produkte anzuzeigen oder zur Marktforschung verwenden wir auf verschiedenen Seiten sogenannte Cookies.
          Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an
          einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Cookies sind kleine
          Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten Cookies
          werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog.
          Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim nächsten
          Besuch wiederzuerkennen (persistente Cookies). Die Dauer der Speicherung können Sie der Übersicht in den
          Cookie-Einstellungen Ihres Webbrowsers entnehmen. Sie können Ihren Browser so einstellen, dass Sie über das
          Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für
          bestimmte Fälle oder generell ausschließen. Jeder Browser unterscheidet sich in der Art, wie er die
          Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert,
          wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den folgenden
          Links:
          <br><br>
        <ul>
          <li>
            <a class="link-btn" href="http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies">
              Internet Explorer™
            </a>
          </li>
          <li>
            <a class="link-btn" href="https://support.apple.com/kb/ph21411?locale=de_DE">
              Safari™
            </a>
          </li>
          <li>
            <a class="link-btn" href="http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647">
              Chrome™
            </a>
          </li>
          <li>
            <a class="link-btn" href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
              Firefox™
            </a>
          </li>
          <li>
            <a class="link-btn" href="http://help.opera.com/Windows/10.20/de/cookies.html">
              Opera™
            </a>
          </li>
        </ul>
        Bei der Nichtannahme von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
        </p>
        <h5 class="my-4 h5-bw">Einsatz von Google (Universal) Analytics zur Webanalyse</h5>
        <p class="p-bw">
          Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO (Cookie-Einwilligungsfunktionalität ab
          25.05.2018 auf unserer Website aktiv) erteilt haben, setzt diese Website zum Zweck der Webseitenanalyse Google
          (Universal) Analytics ein, einen Webanalysedienst der Google LLC (www.google.de). Google (Universal) Analytics
          verwendet Methoden, die eine Analyse der Benutzung der Website durch Sie ermöglichen, wie zum Beispiel Cookies.
          Die automatisch erhobenen Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server
          von Google in den USA übertragen und dort gespeichert. Durch die Aktivierung der IP-Anonymisierung auf dieser
          Webseite wird dabei die IP-Adresse vor der Übermittlung innerhalb der Mitgliedstaaten der Europäischen Union
          oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in
          Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die
          im Rahmen von Google Analytics von Ihrem Browser übermittelte anonymisierte IP-Adresse wird grundsätzlich nicht
          mit anderen Daten von Google zusammengeführt. Nach Zweckfortfall und Ende des Einsatzes von Google Analytics
          durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht.
          <br><br>
          Die Google LLC hat ihren Hauptsitz in den USA und ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles
          Zertifikat kann hier eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen
          Kommission hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes
          Datenschutzniveau festgestellt.
          <br><br>
          Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem sie das unter dem folgenden
          Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
          Hierdurch wird die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
          (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindert.
          <br><br>
          Alternativ zum Browser-Plugin können Sie diesen Link klicken, um die Erfassung durch Google Analytics auf dieser
          Website zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem Endgerät abgelegt. Löschen Sie Ihre
          Cookies, werden Sie erneut um Erteilung Ihrer Einwilligung gebeten.
        </p>

        <h4 class="my-4 h4-bw">7. Werbung über Marketing-Netzwerke</h4>
        <h5 class="my-4 h5-bw">Google AdWords Remarketing</h5>
        <p class="p-bw">
          Über Google Adwords werben wir für diese Website in den Google Suchergebnissen sowie auf den Websites Dritter.
          Soweit Sie uns hierzu Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO
          (Cookie-Einwilligungsfunktionalität ab 25.05.2018auf unserer Website aktiv) erteilt haben, wird bei Besuch
          unserer Website das sog. Remarketing Cookie von Google gesetzt, das automatisch mittels einer pseudonymen
          CookieID und auf Grundlage der von Ihnen besuchten Seiten eine interessenbasierte Werbung ermöglicht. Nach
          Zweckfortfall und Ende des Einsatzes von Google AdWords Remarketing durch uns werden die in diesem Zusammenhang
          erhobenen Daten gelöscht.
          <br><br>
          Eine darüber hinausgehende Datenverarbeitung findet nur statt, sofern Sie gegenüber Google zugestimmt haben,
          dass Ihr Web- und App-Browserverlauf von Google mit ihrem Google-Konto verknüpft wird und Informationen aus
          ihrem Google-Konto zum Personalisieren von Anzeigen verwendet werden, die sie im Web sehen. Sind sie in diesem
          Fall während des Seitenbesuchs unserer Webseite bei Google eingeloggt, verwendet Google Ihre Daten zusammen mit
          Google Analytics-Daten, um Zielgruppenlisten für geräteübergreifendes Remarketing zu erstellen und zu
          definieren. Dazu werden Ihre personenbezogenen Daten von Google vorübergehend mit Google Analytics-Daten
          verknüpft, um Zielgruppen zu bilden.
          <br><br>
          Google AdWords Remarketing ist ein Angebot der Google LLC (www.google.de). Die Google LLC hat ihren Hauptsitz in
          den USA und ist zertifiziert unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann hier eingesehen
          werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission hat letztere für unter dem
          Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
          <br><br>
          Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie das Remarketing-Cookie
          über diesen Link deaktivieren. Daneben können Sie sich bei der Digital Advertising Alliance über das Setzen von
          Cookies informieren und Einstellungen hierzu vornehmen.
        </p>

        <h4 class="my-4 h4-bw">8. Social Media PlugIns</h4>
        <h5 class="my-4 h5-bw">Verwendung von Social Plugins von Facebook, Instagram</h5>
        <p class="p-bw">
          Auf unserer Website werden sogenannte Social Plugins („Plugins“) von sozialen Netzwerken verwendet. Wenn Sie
          eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte
          Verbindung zu den Servern von Facebook, Google, Twitter oder Instagram her. Der Inhalt des Plugins wird vom
          jeweiligen Anbieter direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch die Einbindung der
          Plugins erhalten die Anbieter die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts
          aufgerufen hat, auch wenn Sie kein Profil besitzen oder gerade nicht eingeloggt sind. Diese Information
          (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server des jeweiligen Anbieters (ggf.
          in die USA) übermittelt und dort gespeichert. Sind Sie bei einem der Dienste eingeloggt, können die Anbieter den
          Besuch unserer Website Ihrem Profil in dem jeweiligen sozialen Netzwerk unmittelbar zuordnen. Wenn Sie mit den
          Plugins interagieren, zum Beispiel den „Like“- oder den „Share“-Button betätigen, wird die entsprechende
          Information ebenfalls direkt an einen Server der Anbieter übermittelt und dort gespeichert. Die Informationen
          werden außerdem in dem sozialen Netzwerk veröffentlicht und dort Ihren Kontakten angezeigt. Dies dient der
          Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimalen
          Vermarktung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
          <br><br>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch die Anbieter sowie
          eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
          Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen der Anbieter:
          <br><br>
        <ul>
          <li>http://www.facebook.com/policy.php</li>
          <li>https://help.instagram.com/155833707900388</li>
        </ul>
        Wenn Sie nicht möchten, dass die sozialen Netzwerke die über unseren Webauftritt gesammelten Daten unmittelbar
        Ihrem Profil in dem jeweiligen Dienst zuordnen, müssen Sie sich vor Ihrem Besuch unserer Website bei dem
        entsprechenden Dienst ausloggen. Sie können das Laden der Plugins auch mit Add-Ons für Ihren Browser komplett
        verhindern, z. B. mit dem Skript-Blocker „NoScript“ (http://noscript.net/).
        </p>

        <h5 class="my-4 h5-bw">Youtube Video Plugins</h5>
        <p class="p-bw">
          Auf dieser Webseite werden Inhalte dritter Anbieter eingebunden. Diese Inhalte werden von Google LLC zur
          Verfügung gestellt („Anbieter“). Youtube wird betrieben von der Google LLC, 1600 Amphitheatre Parkway, Mountain
          View, CA 94043, USA („Google“).
          <br><br>
          Bei Videos von Youtube, die auf unserer Seite eingebunden sind, ist die erweiterte Datenschutzeinstellung
          aktiviert. Das bedeutet, dass keine Informationen von Webseitenbesuchern bei Youtube erhoben und gespeichert
          werden, es sei denn, diese spielen das Video ab. Die Einbindung der Videos dient der Wahrung unserer im Rahmen
          einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimalen Vermarktung unseres Angebots
          gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
          <br><br>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch die Anbieter sowie
          Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den
          Datenschutzhinweisen von Google http://www.google.com/intl/de/+/policy/+1button.html.
        </p>

        <h4 class="my-4 h4-bw">9. Versand von Bewertungserinnerungen per E-Mail</h4>
        <h5 class="my-4 h5-bw">Bewertungserinnerung durch Trusted Shops</h5>
        <p class="p-bw">
          Sofern Sie uns hierzu während oder nach Ihrer Bestellung Ihre ausdrückliche Einwilligung gemäß Art. 6 Abs. 1 S.
          1 lit. a DSGVO erteilt haben, übermitteln wir Ihre E-Mail-Adresse an die Trusted Shops GmbH, Subbelrather Str.
          15c, 50823 Köln (www.trustedshops.de), damit diese Ihnen eine Bewertungserinnerung per E-Mail zusendet.
          <br><br>
          Diese Einwilligung kann jederzeit durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder direkt
          gegenüber Trusted Shops widerrufen werden.
        </p>

        <h4 class="my-4 h4-bw">10. Kontaktmöglichkeiten und Ihre Rechte</h4>
        <p class="p-bw">
          Als Betroffener haben Sie folgende Rechte:
          <br><br>
          gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten zu verlangen;
          <br>
          gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
          gespeicherten personenbezogenen Daten zu verlangen;
          <br>
          gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,
          soweit nicht die weitere Verarbeitung – zur Ausübung des Rechts auf freie Meinungsäußerung und
          Information; –
          zur Erfüllung einer rechtlichen Verpflichtung; – aus Gründen des öffentlichen Interesses oder – zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
          <br>
          gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,
          soweit – die Richtigkeit der Daten von Ihnen bestritten wird; – die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen; – wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen benötigen oder – Sie gemäß Art. 21 DSGVO Widerspruch gegen die
          Verarbeitung eingelegt haben;
          <br>
          gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
          strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen
          Verantwortlichen zu verlangen;
          gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich
          hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
          Unternehmenssitzes wenden.
          <br><br>
          Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung,
          Sperrung oder Löschung von Daten sowie Widerruf ggf. erteilter Einwilligungen oder Widerspruch gegen eine
          bestimmte Datenverwendung wenden Sie sich bitte an uns:
          <br><br>
          Barliner Workout,
          <br>
          Inh. Denis Vitaliev
          <br><br>
          Heinrich-Heine-Str. 5
          <br>
          10179 Berlin
          <br><br>
          E-Mail: denis.vitaliev@barlinerworkout.com
        </p>
        <h5 class="my-4 h5-bw">Widerspruchsrecht</h5>
        <p class="p-bw">
          Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen
          personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die
          Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht
          jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein
          Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.
          <br><br>
          Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken
          verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
          Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen dient.
          <br><br>
          Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre
          personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.
          <br><br>
          Stand: 31.01.2024
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<style lang="scss" scoped>
#privacyPolicy {
  max-width: 980px;
  margin: 0 auto;
}
</style>
<script>
export default {
}
</script>
